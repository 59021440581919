@import "~@angular/material/prebuilt-themes/indigo-pink.css";

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}
  
.dialog__container {
  backdrop-filter: blur(8px) !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
}