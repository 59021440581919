@import "~@angular/material/prebuilt-themes/indigo-pink.css";

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.search-form-container {
  backdrop-filter: blur(8px) !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
}

.video-modal-container .mat-dialog-container {
  background-color: black !important;
}

.mat-dialog-container .ng-star-inserted .mat-dialog-content {
  margin: 0 !important;
  max-height: fit-content;
}